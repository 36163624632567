import * as React from "react"
import {graphql} from 'gatsby';
import { Datenschutzerklärung } from "../components/datenschutzerklärung/Datenschutzerklärung"
import { DatenschutzerklärungEn } from "../components/datenschutzerklärung/DatenschutzerklärungEn";


const DatenschutzerklärungPage = () => (
<>
{
  // какая-то дичь ;)
  typeof window !== 'undefined' && window.location.pathname === '/de/datenschutzerkl%C3%A4rung' ?
   <Datenschutzerklärung/> : <DatenschutzerklärungEn/>
}
</>
)

export default DatenschutzerklärungPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;