import React from 'react'
import * as  s from '../datenschutzerklärung/Datenschutzerklärung.module.css'
import { Footer } from '../screens/footer/Footer'
import { Navbar } from '../ui/navbar/Navbar'
export const DatenschutzerklärungEn = () => {
    return (
        <div className={s.wrapper}>
      
        <div className={s.content}>
            <Navbar/>
            <h1>
                Impressum & Datenschutz
            </h1>
            <span>
                WEmkIT GmbH
            </span>
            <span>
                Pappelallee 78 / 79
            </span>
            <span>
                10407 Berlin
            </span>
            <span>
                Phone: +4930-57711784
            </span>
            <span>
                Email: info@wemkit.io
            </span>
            <h2>
                Data protection
            </h2>
            <p>
                Thank you for your interest in our company. Data protection is of a particularly high priority for the management of Wemkit.io. The web pages of Websulting.de can generally be used without providing any personal data. However, if a data subject wishes to make use of our company's special services via our website, it may be necessary to process personal data. If the processing of personal data is necessary and there is no legal basis for such processing, we generally obtain the consent of the person concerned.
            </p>
            <p>
                The processing of personal data, for example the name, address, email address or telephone number of a person concerned, is always carried out in accordance with the General Data Protection Regulation and in accordance with the country-specific data protection regulations applicable to Wemkit.io. By means of this data protection declaration, our company would like to inform the public about the type, scope and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed about their rights by means of this data protection declaration.
            </p>
            <p>
                As the controller, Wemkit.io has implemented numerous technical and organizational measures to ensure the most complete protection possible for personal data processed through this website. Nevertheless, internet-based data transmissions can generally have security gaps, so that absolute protection cannot be guaranteed. For this reason, every person concerned is free to transmit personal data to us in alternative ways, for example by telephone. 
            </p>
            <ol>
                <li>
                    <span>
                        Definitions
                    </span>
                    <span>
                         The data protection declaration of Wemkit.io is based on the terms used by the European legislator for directives and regulations when the General Data Protection Regulation (GDPR) was adopted. Our data protection declaration should be easy to read and understand for the public as well as for our customers and business partners. To ensure this, we would like to explain the terminology used in advance.
                    </span>
                    <span>
                    In this data protection declaration, we use the following terms, among others:
                    </span>
                    <ol>
                        <li>
                            <span>
                            a) personal data
                            </span>
                            <span>
                            Personal data is all information that relates to an identified or identifiable natural person (hereinafter 'data subject'). A natural person is regarded as identifiable who, directly or indirectly, in particular by means of assignment to an identifier such as a name, an identification number, location data, an online identifier or one or more special features that express the physical, physiological, genetic, psychological, economic, cultural or social identity of this natural person can be identified. 
                            </span>
                        </li>
                        <li>
                            <span>
                            b) data subject
                            </span>
                            <span>
                            The data subject is any identified or identifiable natural person whose personal data is processed by the person responsible for processing. 
                            </span>
                        </li>
                        <li>
                            <span>
                            c) processing
                            </span>
                            <span>
                            Processing is any process carried out with or without the help of automated procedures or any such series of processes in connection with personal data such as the collection, recording, organization, ordering, storage, adaptation or change, reading, querying, use, Disclosure through transmission, dissemination or any other form of provision, comparison or linking, restriction, deletion or destruction.
                            </span>
                        </li>
                        <li>
                            <span>
                            d) Restriction of processing 
                            </span>
                            <span>
                            Restriction of processing is the marking of stored personal data with the aim of restricting their future processing.
                            </span>
                        </li>
                        <li>
                            <span>
                            e) Profiling
                            </span>
                            <span>
                            Profiling is any type of automated processing of personal data that consists of using this personal data to evaluate certain personal aspects relating to a natural person, in particular aspects relating to work performance, economic situation, health, personal To analyze or predict the preferences, interests, reliability, behavior, whereabouts or relocation of this natural person. 
                            </span>
                        </li>
                        <li>
                            <span>
                            f) pseudonymization
                            </span>
                            <span>
                            Pseudonymization is the processing of personal data in a way in which the personal data can no longer be assigned to a specific data subject without the use of additional information, provided that this additional information is stored separately and is subject to technical and organizational measures that guarantee that the personal data cannot be assigned to an identified or identifiable natural person.  
                            </span>
                        </li>
                        <li>
                            <span>
                            g) Controller or controller
                            </span>
                            <span>
                            The person responsible or the person responsible for the processing is the natural or legal person, authority, institution or other body that alone or jointly with others decides on the purposes and means of processing personal data. If the purposes and means of this processing are specified by Union law or the law of the member states, the person responsible or the specific criteria for his appointment can be provided for in accordance with Union law or the law of the member states.
                            </span>
                        </li>
                        <li>
                            <span>
                            h) Processors
                            </span>
                            <span>
                            Processor is a natural or legal person, authority, institution or other body that processes personal data on behalf of the person responsible.
                            </span>
                        </li>
                        <li>
                            <span>
                            i) Recipient 
                            </span>
                            <span>
                            Recipient is a natural or legal person, authority, institution or other body to which personal data is disclosed, regardless of whether it is a third party or not. However, authorities that may receive personal data as part of a specific investigation according to Union law or the law of the member states are not considered recipients.
                            </span>
                        </li>
                        <li>
                            <span>
                            j) third parties
                            </span>
                            <span>
                            A third party is a natural or legal person, public authority, agency or body other than the person concerned, the person responsible, the processor and the persons who are authorized to process the personal data under the direct responsibility of the person responsible or the processor. 
                            </span>
                        </li>
                        <li>
                            <span>
                            k) Consent
                            </span>
                            <span>
                            Consent is any voluntary, informed and unambiguous declaration of intent given by the person concerned for the specific case in the form of a declaration or other unequivocal affirmative action with which the person concerned indicates that they consent to the processing of their personal data is. 
                            </span>
                        </li>
                    </ol>
                </li>
                <li>
                    <span>
                    Name and address of the person responsible for processing 
                    </span>
                    <span>
                    The person responsible within the meaning of the General Data Protection Regulation, other data protection laws applicable in the member states of the European Union and other provisions of a data protection nature is:  
                    </span>
                    <span>
                     WEmkIT GmbH
                    </span>
                    <span>
                    Sucharev Ilja
                    </span>
                    <span>
                    Pappelallee 78 / 79
                    </span>
                    <span>
                    10407 Berlin    
                    </span>
                    <a href="mailto:info@wemkit.io" >
                     info@wemkit.io
                    </a>
                    <a href="tel:+493057711784" >
                    +4930-57711784
                    </a>
                </li>
                <li>
                    <span>
                    Cookies
                    </span>
                    <span>
                    The Internet pages of Wemkit.io use cookies. Cookies are text files that are stored and stored on a computer system via an internet browser. 
                    </span>
                    <span>
                    Numerous websites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier for the cookie. It consists of a string of characters through which websites and servers can be assigned to the specific Internet browser in which the cookie was stored. This enables the websites and servers visited to distinguish the individual browser of the person concerned from other Internet browsers that contain other cookies. A specific internet browser can be recognized and identified via the unique cookie ID.
                    </span>
                    <span>
                    Through the use of cookies, Wemkit.io can provide the users of this website with more user-friendly services that would not be possible without the cookie setting. 
                    </span>
                    <span>
                    Using a cookie, the information and offers on our website can be optimized in the interests of the user. As already mentioned, cookies enable us to recognize the users of our website. The purpose of this recognition is to make it easier for users to use our website. For example, the user of a website that uses cookies does not have to re-enter their access data every time they visit the website, because this is done by the website and the cookie stored on the user's computer system. Another example is the cookie of a shopping cart in the online shop. The online shop uses a cookie to remember the items that a customer has placed in the virtual shopping cart.
                    </span>
                    <span>
                    The person concerned can prevent the setting of cookies by our website at any time by means of a corresponding setting in the Internet browser used and thus permanently object to the setting of cookies. Furthermore, cookies that have already been set can be deleted at any time via an Internet browser or other software programs. This is possible in all common internet browsers. If the person concerned deactivates the setting of cookies in the internet browser used, not all functions of our website may be fully usable.
                    </span>
                </li>
                <li>
                    <span>
                    Collection of general data and information
                    </span>
                    <span>
                    The Wemkit.io website collects a range of general data and information each time the website is accessed by a data subject or an automated system. These general data and information are stored in the server's log files. The (1) browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system reaches our website (so-called referrer), (4) the sub-websites that are accessed via an accessing system on our website can be controlled, (5) the date and time of access to the website, (6) an internet protocol address (IP address), (7) the internet service provider of the accessing system and (8) other similar data and information that serve to avert danger in the event of attacks on our information technology systems.
                    </span>
                    <span>
                    When using this general data and information, Wemkit.io does not draw any conclusions about the person concerned. Rather, this information is required to (1) correctly deliver the content of our website, (2) optimize the content of our website and the advertising for it, (3) ensure the long-term functionality of our information technology systems and the technology of our website and ( 4) to provide law enforcement authorities with the information necessary for law enforcement in the event of a cyber attack. This anonymously collected data and information is therefore statistically and further evaluated by Websulting.de with the aim of increasing data protection and data security in our company in order to ultimately ensure an optimal level of protection for the personal data processed by us. The anonymous data in the server log files are stored separately from all personal data provided by a data subject.
                    </span>
                </li>
                <li>
                    <span>
                    Contact option via the website
                    </span>
                    <span>
                    Due to legal regulations, the Wemkit.io website contains information that enables quick electronic contact to our company and direct communication with us, which also includes a general address for so-called electronic mail (e-mail address). If a data subject contacts the person responsible for processing by email or a contact form, the personal data transmitted by the data subject will be automatically saved. Such personal data transmitted on a voluntary basis by a data subject to the person responsible for processing are stored for the purposes of processing or contacting the data subject. This personal data is not passed on to third parties.
                    </span>
                </li>
                <li>
                    <span>
                    Routine deletion and blocking of personal data
                    </span>
                    <span>
                    The person responsible for the processing processes and stores personal data of the data subject only for the period of time that is necessary to achieve the storage purpose or if this is specified by the European directives and regulations or another legislator in laws or regulations, which the person responsible for the processing is subject to, was provided
                    </span>
                    <span>
                    If the purpose of storage no longer applies or if a storage period prescribed by the European directives and regulations or another responsible legislator expires, the personal data will be routinely blocked or deleted in accordance with the statutory provisions.
                    </span>
                </li>
                <li>
                    <span>
                    Rechte der betroffenen Person
                    </span>
                </li>
                <li>
                    <ol>
                        <li>
                            <span>
                            a) Right to confirmation
                            </span>
                            <span>
                            Every person concerned has the right granted by the European directive and regulation giver to request confirmation from the person responsible for the processing as to whether personal data concerning them are being processed. If a data subject wishes to exercise this right to confirmation, they can contact an employee of the person responsible for processing at any time.
                            </span>
                        </li>
                        <li>
                            <span>
                            b) Right to information
                            </span>
                            <span>
                            Every person affected by the processing of personal data has the right granted by the European directive and regulation giver to receive free information about the personal data stored about him and a copy of this information from the person responsible for the processing at any time. Furthermore, the European directives and regulations grant the data subject access to the following information:  
                            </span>
                            <span>
                            The processing purposes <br />
                             the categories of personal data that are processed <br />
                             the recipients or categories of recipients to whom the personal data have been disclosed or are still being 
                             disclosed, in particular for recipients in third countries or international organizations <br />
                              if possible the planned duration for which the personal data is stored, or, if this is not possible,
                               the criteria for determining this duration <br />
                               the existence of a right to correction or deletion of the personal data concerning them or to
                                restriction of processing by the person responsible or a right of objection against this processing <br />
                                 the right to lodge a complaint with a supervisory authority <br />
                                  if the personal data are not collected from the data subject: all available information about the 
                                  origin of the data <br />
                                   the existence of automated decision-making e Including profiling in accordance with Article 22
                                    (1) and (4) GDPR and - at least in these cases - meaningful information about
                                     the logic involved and the scope and intended effects of such processing for the person concerned <br />
                                      Furthermore, the person concerned has a right to information about it whether personal data 
                                      has been transmitted to a third country or to an international organization. If this is the case,
                                       the data subject also has the right to receive information about the appropriate guarantees 
                                       in connection with the transmission.
                            </span>
                            <span>
                            If a person concerned wishes to exercise this right to information, they can contact an employee of the person responsible for processing at any time.
                            </span>
                        </li>
                        <li>
                            <span>
                            c) Right to rectification 
                            </span>
                            <span>
                            Every person affected by the processing of personal data has the right granted by the European directives and regulations to request the immediate correction of incorrect personal data concerning them. Furthermore, the data subject has the right, taking into account the purposes of the processing, to request the completion of incomplete personal data - including by means of a supplementary declaration.
                            </span>
                            <span>
                            Anyone affected by the processing of personal data If a person concerned wishes to make use of this right to correction, they can contact an employee of the person responsible for processing at any time. The person has the right granted by the European directive and regulation giver to make immediate corrections concerning them to request incorrect personal data. Furthermore, the data subject has the right, taking into account the purposes of the processing, to request the completion of incomplete personal data - including by means of a supplementary declaration.
                            </span>
                        </li>
                        <li>
                            <span>
                            d) Right to erasure (right to be forgotten)
                            </span>
                            <span>
                            Every person affected by the processing of personal data has the right granted by the European directive and regulation giver to demand that the person responsible delete the personal data concerning them immediately, provided that one of the following reasons applies and insofar as the processing is not necessary: 
                            </span>
                            <span>
                            The personal data were collected or otherwise processed for purposes for which they are no longer necessary. The data subject revokes their consent, on which the processing was based in accordance with Article 6 (1) (a) GDPR or Article 9 (2) (a) GDPR, and there is no other legal basis for the processing. The data subject objects to the processing in accordance with Art. 21 Paragraph 1 GDPR, and there are no overriding legitimate reasons for the processing, or the data subject objects in accordance with Art. 21 Paragraph 2 GDPR to the Processing a. The personal data was processed unlawfully. The deletion of personal data is necessary to fulfill a legal obligation under Union law or the law of the member states to which the person responsible is subject. The personal data was collected in relation to the information society services offered in accordance with Art. 8 Para. 1 GDPR. If one of the above-mentioned reasons applies and a person concerned would like to have personal data stored at Websulting.de deleted, they can contact an employee of the person responsible for processing at any time. The Websulting.de employee will arrange for the deletion request to be complied with immediately.  
                            </span>
                            <span>
                            If the personal data has been made public by Websulting.de and our company, as the person responsible, is obliged to delete the personal data in accordance with Art. 17 Paragraph 1 GDPR, Websulting.de takes appropriate measures, taking into account the available technology and the implementation costs , also of a technical nature, in order to inform other data processors who process the published personal data that the data subject has requested the deletion of all links to this personal data or copies or replications of these other data processors has requested personal data insofar as the processing is not necessary. The Websulting.de employee will arrange the necessary in individual cases.
                            </span>
                        </li>
                        <li>
                            <span>
                            e) Right to restriction of processing
                            </span>
                            <span>
                            Every person affected by the processing of personal data has the right, granted by the European legislator of directives and regulations, to require the controller to restrict processing if one of the following conditions is met:
                            </span>
                            <span>
                            The correctness of the personal data is contested by the data subject for a period of time that enables the person responsible to check the correctness of the personal data. The processing is unlawful, the person concerned refuses to delete the personal data and instead requests that the use of the personal data be restricted. The person responsible no longer needs the personal data for the purposes of processing, but the data subject needs them to assert, exercise or defend legal claims. The person concerned has lodged an objection to the processing in accordance with Art. 21 Paragraph 1 GDPR and it has not yet been determined whether the legitimate reasons of the person responsible outweigh those of the person concerned. If one of the above conditions is met and a person concerned would like to request the restriction of personal data stored at Websulting.de, they can contact an employee of the person responsible for processing at any time. The Websulting.de employee will arrange for the processing to be restricted. 
                            </span>
                        </li>
                        <li>
                            <span>
                            f) Right to data portability
                            </span>
                            <span>
                            Every person affected by the processing of personal data has the right granted by the European legislator of directives and regulations to receive the personal data relating to them, which the person concerned has provided to a person responsible, in a structured, common and machine-readable format. You also have the right to transfer this data to another person responsible without hindrance from the person responsible to whom the personal data was provided, provided that the processing is based on the consent in accordance with Art. 6 Para. 1 Letter a GDPR or Art. 9 Para . 2 letter a DS-GVO or on a contract according to Art. 6 para. 1 letter b DS-GVO and the processing is carried out using automated procedures, unless the processing is necessary for the performance of a task that is in the public interest or takes place in the exercise of public authority, which has been assigned to the person responsible.  
                            </span>
                            <span>
                            Furthermore, when exercising their right to data portability in accordance with Art. 20 (1) GDPR, the data subject has the right to have the personal data transmitted directly from one person responsible to another, insofar as this is technically feasible and if this does not affect the rights and freedoms of other persons.
                            </span>
                            <span>
                            To assert the right to data portability, the person concerned can contact an employee of Wemkit.io at any time.
                            </span>
                        </li>
                        <li>
                            <span>
                            g) Right to object
                            </span>
                            <span>
                            Any person affected by the processing of personal data has the right granted by the European legislator of directives and regulations to object at any time to the processing of personal data relating to them which is based on Art. 6 Paragraph 1 Letter e or f DS-GVO takes place, to object. This also applies to profiling based on these provisions. 
                            </span>
                            <span>
                            Wemkit.io will no longer process the personal data in the event of an objection, unless we can prove compelling legitimate reasons for the processing that outweigh the interests, rights and freedoms of the data subject, or the processing serves to assert, exercise or Defense of legal claims.
                            </span>
                            <span>
                            If Wemkit.io processes personal data in order to operate direct mail, the data subject has the right to object at any time to the processing of personal data for the purpose of such advertising. This also applies to profiling insofar as it is associated with such direct mail. If the data subject objects to Wemkit.io processing for direct marketing purposes, Wemkit.io will no longer process the personal data for these purposes.
                            </span>
                            <span>
                            In addition, the data subject has the right, for reasons that arise from their particular situation, to object to the processing of personal data concerning them that is carried out by Websulting.de for scientific or historical research purposes or for statistical purposes in accordance with Art. 89 Para. 1 DS -GVO take an objection, unless such processing is necessary to fulfill a task in the public interest. 
                            </span>
                            <span>
                            To exercise the right to object, the data subject can contact any Websulting.de employee or another employee directly. The data subject is also free, in connection with the use of information society services, regardless of Directive 2002/58 / EC, to exercise their right of objection by means of automated procedures in which technical specifications are used. 
                            </span>
                        </li>
                        <li>
                            <span>
                            h) Automated decisions in individual cases including profiling 
                            </span>
                            <span>
                            Any person affected by the processing of personal data has the right granted by the European legislator of directives and regulations not to be subjected to a decision based solely on automated processing - including profiling - which has legal effects on them or similarly significantly affects them, provided that the decision (1) is not necessary for the conclusion or performance of a contract between the data subject and the person responsible, or (2) is permissible on the basis of Union or Member State law to which the person responsible is subject, and these legal provisions take appropriate measures to safeguard the rights and freedoms as well as the legitimate interests of the data subject or (3) is made with the express consent of the data subject. 
                            </span>
                            <span>
                            If the decision (1) is necessary for the conclusion or performance of a contract between the data subject and the person responsible or (2) it is made with the express consent of the data subject, Websulting.de takes appropriate measures to safeguard the rights and freedoms as well as the To safeguard the legitimate interests of the data subject, including at least the right to obtain intervention by a person on the part of the person responsible, to express his or her own point of view and to contest the decision.  
                            </span>
                            <span>
                            If the data subject wishes to assert rights with regard to automated decisions, they can contact an employee of the person responsible for processing at any time.  
                            </span>
                        </li>
                        <li>
                            <span>
                            i) Right to withdraw consent under data protection law
                            </span>
                            <span>
                            Every person affected by the processing of personal data has the right granted by the European legislator of directives and regulations to revoke their consent to the processing of personal data at any time. 
                            </span>
                            <span>
                            If the person concerned wishes to assert their right to withdraw consent, they can contact an employee of the person responsible for processing at any time. 
                            </span>
                        </li>
                    </ol>
                </li>
                <li>
                    <span>
                    Data protection provisions on the application and use of Google Analytics (with anonymization function) 
                    </span>
                    <span>
                    The person responsible for processing has integrated the Google Analytics component (with anonymization function) on this website. Google Analytics is a web analysis service. Web analysis is the collection, collection and evaluation of data on the behavior of visitors to Internet pages. A web analysis service collects, among other things, data on the website from which a person concerned came to a website (so-called referrer), which sub-pages of the website were accessed or how often and for how long a sub-page was viewed. A web analysis is mainly used to optimize a website and for the cost-benefit analysis of Internet advertising 
                    </span>
                    <span>
                    The operating company of the Google Analytics component is Google Inc., 1600 Amphitheater Pkwy, Mountain View, CA 94043-1351, USA. 
                    </span>
                    <span>
                    The purpose of the Google Analytics component is to analyze the flow of visitors to our website. Google uses the data and information obtained, among other things, to evaluate the use of our website, to compile online reports for us that show the activities on our website, and to provide other services related to the use of our website. 
                    </span>
                    <span>
                    Google Analytics places a cookie on the information technology system of the person concerned. What cookies are has already been explained above. With the setting of the cookie, Google is enabled to analyze the use of our website. Each time one of the individual pages of this website is called up, which is operated by the person responsible for processing and on which a Google Analytics component has been integrated, the Internet browser on the information technology system of the person concerned is automatically triggered by the respective Google Analytics component To transmit data to Google for the purpose of online analysis. As part of this technical process, Google gains knowledge of personal data, such as the IP address of the person concerned, which Google uses, among other things, to trace the origin of the visitors and clicks and subsequently to enable commission accounting.  
                    </span>
                    <span>
                    The cookie is used to store personal information, such as the access time, the location from which access was made and the frequency of visits to our website by the person concerned. Each time you visit our website, this personal data, including the IP address of the Internet connection used by the person concerned, is transmitted to Google in the United States of America. These personal data are stored by Google in the United States of America. Google may pass this personal data collected through the technical process on to third parties.  
                    </span>
                    <span>
                    The person concerned can prevent the setting of cookies by our website, as already shown above, at any time by means of a corresponding setting in the Internet browser used and thus permanently object to the setting of cookies. Such a setting of the internet browser used would also prevent Google from setting a cookie on the information technology system of the person concerned. In addition, a cookie already set by Google Analytics can be deleted at any time via the Internet browser or other software programs.
                    </span>
                    <span>
                    Furthermore, the data subject has the option of objecting to the collection of the data generated by Google Analytics relating to the use of this website as well as the processing of this data by Google and to prevent this. To do this, the person concerned must download and install a browser add-on under the link https://tools.google.com/dlpage/gaoptout. This browser add-on informs Google Analytics via JavaScript that no data or information on visits to websites may be transmitted to Google Analytics. The installation of the browser add-on is considered an objection by Google. If the information technology system of the person concerned is deleted, formatted or reinstalled at a later point in time, the person concerned must reinstall the browser add-on in order to deactivate Google Analytics. If the browser add-on is uninstalled or deactivated by the person concerned or another person who is attributable to their sphere of influence, there is the option of reinstalling or reactivating the browser add-on. 
                    </span>
                    <span>
                    Furthermore, the data subject has the option of recording the data generated by Google Analytics on a
                        to object to the use of this website-related data and the processing of this data by Google and a
                        to prevent such. To do this, the person concerned must use a browser add-on under the link https://tools.google.com/dlpage/gaoptout
                        download and install. This browser add-on tells Google Analytics via JavaScript that no data and information
                        may be transmitted to Google Analytics for visits to websites. The browser add-on is installed by
                        Google rated as a contradiction. Will the information technology system of the data subject at a later date
                        deleted, formatted or reinstalled, the data subject must reinstall the browser add-on,
                        to disable Google Analytics. If the browser add-on is used by the data subject or another person who
                        is attributable to a sphere of influence, is uninstalled or deactivated, there is the possibility of reinstallation or reinstallation
                        Activation of the browser add-on.
                    </span>
                    <span>
                    Further information and the applicable data protection provisions of Google can be found at
                        <a href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/</a> 
                        and under
                        <a href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a>
                        can be accessed. Google Analytics is available at this link
                        <a href="https://www.google.com/intl/de_de/analytics/"> https://www.google.com/intl/de_de/analytics/</a>
                        explained in more detail.
                    </span>
                </li>
                <li>
                    <span>
                    Legal basis for processing
                    </span>
                    <span>
                    Art. 6 I lit. a GDPR serves our company as the legal basis for processing operations for which we obtain consent for a specific processing purpose. If the processing of personal data is necessary to fulfill a contract to which the data subject is a party, as is the case, for example, with processing operations that are necessary for the delivery of goods or the provision of other services or consideration, the processing is based on Art. 6 I lit. b GDPR. The same applies to such processing operations that are necessary to carry out pre-contractual measures, for example in cases of inquiries about our products or services. If our company is subject to a legal obligation which requires the processing of personal data, for example to fulfill tax obligations, the processing is based on Art. 6 I lit. c GDPR. In rare cases, it may be necessary to process personal data in order to protect the vital interests of the data subject or another natural person. This would be the case, for example, if a visitor to our company were injured and his name, age, health insurance data or other vital information would then have to be passed on to a doctor, hospital or other third party. Then the processing would be based on Art. 6 I lit. d GDPR. Ultimately, processing operations could be based on Art. 6 I lit. f GDPR. Processing operations that are not covered by any of the aforementioned legal bases are based on this legal basis if the processing is necessary to safeguard a legitimate interest of our company or a third party, provided that the interests, fundamental rights and freedoms of the person concerned do not prevail. We are particularly permitted to carry out such processing operations because they have been specifically mentioned by the European legislator. In this respect, he took the view that a legitimate interest could be assumed if the person concerned is a customer of the person responsible (recital 47 sentence 2 GDPR)
                    </span>
                </li>
                <li>
                    <span>
                    Legitimate interests in processing that are being pursued by the controller or a third party
                    </span>
                    <span>
                    If the processing of personal data is based on Article 6 I lit.f GDPR, our legitimate interest is the conduct of our business activities for the benefit of all our employees and our shareholders. 
                    </span>
                </li>
                <li>
                    <span>
                    Duration for which the personal data are stored
                    </span>
                    <span>
                    The criterion for the duration of the storage of personal data is the respective statutory retention period. After the deadline has expired, the relevant data is routinely deleted, provided that it is no longer required to fulfill or initiate a contract. 
                    </span>
                </li>
                <li>
                    <span>
                    Legal or contractual provisions for the provision of personal data; Necessity for the conclusion of the contract; Obligation of the data subject to provide the personal data; possible consequences of non-provision 
                    </span>
                    <span>
                    We explain to you that the provision of personal data is in part required by law (e.g. tax regulations) or may result from contractual regulations (e.g. information on the contractual partner). In order to conclude a contract, it may sometimes be necessary for a data subject to provide us with personal data that we subsequently have to process. For example, the data subject is obliged to provide us with personal data when our company concludes a contract with them. Failure to provide personal data would mean that the contract could not be concluded with the person concerned. Before the person concerned provides personal data, the person concerned must contact one of our employees. Our employee explains to the person concerned on a case-by-case basis whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data and what consequences the failure to provide the personal data would have.
                    </span>
                </li>
                <li>
                    <span>
                    Existence of automated decision-making
                    </span>
                    <span>
                    As a responsible company, we do not use automatic decision-making or profiling. 
                    </span>
                    <span>
                    This data protection declaration was created by the data protection declaration generator of the DGD Deutsche Gesellschaft für Datenschutz GmbH, which works as the external data protection officer in Bamberg, in cooperation with the IT and data protection lawyer Christian Solmecke. 
                    </span>
                </li>
            </ol>
        </div>    
        <Footer/>           
    </div>
    )
}
